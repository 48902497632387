body{

    @mixin mq($biggerValue){
        @media only screen and (max-width: $biggerValue) {
            @content
        }
    }


    .page_container{

        
        nav{
            .main_nav{
                width: 100%;
                top: 0px;
                background-color: black;
                z-index:99999;
                font-weight: 500;
                line-height: 23px;
                display:  block;
                position:fixed;

                .nav_container{
                    max-width:90%;
                    width:90%;
                    margin:auto;
                    overflow: hidden;


                    .logo_container{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        text-align:left;
                        font-weight: 500;
                        line-height: 23px;

                        .logo_helper{
                            display: inline-block;
                            width: 0;
                            height: 100%;
                            vertical-align: middle;
                        }
                        
                        a{
                            color: #42ddcd;
                            text-decoration: none;
                            text-align: left;
                            box-sizing: border-box;
                            font-weight: 500;
                            line-height: 23px;
                            #logo{
                                display: inline-block;
                                float:none;
                                max-height:54%;
                                margin-bottom: 0;
                                max-width: 100%;
                                height: auto;
                                cursor: pointer;
                                vertical-align: middle;

                                @include mq(980px){
                                    max-width: 50%;
                                }
                            }
                        }
                    }


                    .header{
                        padding-left: 119px;
                        padding-top: 33px;
                        float: right;
                        
                        
                        @include mq (980px){
                            padding-top: 24px;
                        }

                        .menu{
                            float: left;
                            line-height: 0;
                            display: block;
                            font-weight: 600;
                            overflow:hidden;

                            @include mq (980px){
                                display: none;
                            }



                            .menu_container{
                                list-style: none;   
                                height: 100%;
                                float: left;
                                line-height: 0;



                                .menu_content{
                                    text-decoration: none;
                                    padding-right: 30px;
                                    display:inline-block;
                                    position: relative;
                                    line-height: 1em;
                                    font-size: 14px;
                                    line-height: 1em;
                                    padding-bottom:0px;



                                    a{
                                        display: block;
                                        position: relative;
                                        text-decoration: none;
                                        padding-bottom:33px;
                                        color:white;
                                        font-size: 17px;
                                        font-weight: 400;
                                    }

                                    &:last-of-type{
                                        padding-right:0;
                                    }
        

                                    a:hover{
                                        transition: opacity .4s ease-in-out;
                                    }

                                }

                            }
                        }


                        .search{
                            display: block;
                            float: right;
                            position: relative;
                            width: 18px;
                            margin: 3px 0 0 22px;
                            font-weight: 600;
                            text-align: left;
                            line-height: 23px;
                            
                            @include mq(980px){
                                float:left;
                                margin: 0 35px 0 0;
                            }

                            .icon-search{
                                i {
                                    color:#ffffff;
                                    position: absolute;
                                    top: -3px;
                                    left: 0;
                                    font-size: 17px;
                                    font-weight: 400px;
                                    line-height: 1;

                                    @include mq(980px){
                                        top:7px;
                                    }
                                }
                            }
                        }

                        .mobileheader{
                            display:none;
                            float: right;
        
                            @include mq(980px){
                                display: block;
                            }

                            .mobileheader_container{
                                display: block;
        
                                .mobile_menu_bar{
                                    padding-bottom:24px;
                                    display: block;
                                    position: relative;
                                    line-height: 0;
        
        
                                    i{
                                        font-size: 32px;
                                        color:  #fff;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        cursor: pointer;    
                                    }
                                }
        
                                .mobile_menu{
                                    max-width: 90%;
                                    width: 90%;
                                    position: absolute;
                                    top: 80px;
                                    background-color: rgba(0,40,81,0.5);
                                    border-top: 3px solid #fff;
                                    margin:0 5% 0 5%;
                                    display: none;
                                    left: 0;
                                    box-shadow: 0 2px 5px rgba(0,0,0,.1);
                                    padding: 5%;

                                            
                                    .mobile_menu_content{
                                        list-style: none;

                                        a{
                                            text-decoration: none;
                                            visibility: visible;
                                            font-family: 'Karla',Helvetica,Arial,Lucida,sans-serif;
                                            font-weight: 600;
                                            line-height: 23px;
                                            font-size: 14px;
                                            color: #ffffff;
                                            display: block;
                                            padding: 10px 5%;
                                            border-bottom: 1px solid rgba(8, 84, 155,.1);
                                        }
                                    }


                                }     
                                
                                .openmenu{
                                    display: block;
                                }
                            }


                        }



                    }



                }

            }

        }


        .main_content{
            width:100%;
            height: 100%;
            box-sizing: border-box;
            padding: 0;
            margin:0;


            .servicespage{
                position: relative;
                padding: 0px;
            
                .servicespage_background{
                    padding-bottom: 100px;
                    padding-top: 180px;
                    background-image: url(../img/gallery/bethany-legg-9248-unsplash.jpg);
                    position: relative;
                    background-position: center center;
                    background-size: cover;
                    overflow: hidden;
                                    
                    .background_layer{

                        .servicespage_wrapper{
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: center;
        
                            position: relative;
                            z-index: 3;
                            width: 80%;
                            max-width: 1080px;
                            margin-right: auto;
                            margin-left: auto;
            
                            .servicespage_content{
                                width: 100%;
                                align-self: center;
            
                                .servicespage_header{
                                    max-width: 800px;
                                    width: 80%;
                                    margin: 20px auto;
                                    padding: 10px;
                                    text-align: center;
            
                                    h4{
                                        font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                        font-weight: 800;
                                        text-transform: uppercase;
                                        font-size: 46px;
                                        letter-spacing: 4px;
                                        color:white;
                                        line-height: 1.1em;
                                        padding-bottom: 10px;

                                        @include mq(650px){
                                            font-size: 40px;
                                        }
            
                                        @include mq(420px){
                                            font-size: 35px;
                                        }

                                        @include mq(320px){
                                            font-size: 26px;
                                        }

                                        @include mq(280px){
                                            font-size: 20px;
                                            letter-spacing: 2px;
                                        }
                                    }

                                    p{
                                        text-align: center;
                                        font-family: 'Karla',Helvetica,Arial,Lucida,sans-serif;
                                        color: white;
                                        line-height: 1.9em;
                                        font-size: 16px;

                                        @include mq(280px){
                                            font-size: 14px;
                                            letter-spacing: 2px;
                                        }


                                    }
                                }
                            }
                        }
                    }
                } 

                    .servicespage_background::before{
                        content: "";
                        position: absolute;
                        left: 0; 
                        right: 0;
                        top: 0; 
                        bottom: 0;
                        // background-color: rgba(0,40,81,0.77);
                        background-color: rgba(0,0,0,0.65);
                    }
            }
        

            ////////////////////////////////////////////////// SERVICELIST /////////////////////////////////////////////
            ////////////////////////////////////////////////// SERVICELIST/////////////////////////////////////////////
            ////////////////////////////////////////////////// SERVICELIST/////////////////////////////////////////////
        
            .serviceslist{
                background: #f9fcff;
                padding: 4% 0;
                position: relative;
        
        
                .serviceslist_wrapper{
                    max-width: 1080px;
                    width: 80%;
                    position: relative;
                    margin: auto;
                    padding: 2% 0;
        
        
                    .serviceslist_content{
                        width:100%;
                        margin-right: 0;
                        margin-bottom: 0;
                        background-color: rgba(145,145,145,0);
        
        
                        .serviceslist_item{
                            border-color: rgba(217,217,217,0) rgba(217,217,217,0) #F48D1F rgba(217,217,217,0);
                            border-bottom-width: 2px;
                            border-style: solid;
                            margin-bottom: 2.75%;
                            background-color: rgba(0,0,0,0);
                            padding: 20px;


                            .servicelist_text{
                                position: relative;
                                padding: 0 50px 0 0;
                                font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif;
                                font-weight: 500;
                                font-size: 15px;
                                color: #000000;
        
                                i{
                                    position: absolute;
                                    top: 50%;
                                    right: 0;
                                    margin-top: -.5em;
                                    font-size: 16px;
                                    color:black;
        
                                }
                            }


            


                            .open_title{
                                color: #F48D1F;
                                padding: 0 50px 20px 0;

                            }
                        }
                    }
                }
            }
        }
    }


}
